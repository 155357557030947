import React from 'react';
import Layout from '@layouts/Layout';
import Checkpoints from '@components/shop/Checkpoints';
import CustomisePergola from '@components/shop/CustomisePergola/CustomisePergola';

import { BasketProvider } from '@data/context/BasketContext';
/**
 * Customise page
 */
const Customise = () => (
    <Layout mainClassName="flow--xlarge" metaData={{ title: 'Customise Pergola' }}>

        <header className="hero hero--has-checkpoints">
            <div className="container flow">
                <Checkpoints />
                <h1 className="text-subtitle text-align--center">Customise Pergola</h1>
            </div>
        </header>
        <BasketProvider>
            <CustomisePergola />
        </BasketProvider>
    </Layout>
);

export default Customise;
