
import React from 'react';
import { useBasketContext } from '@data/context/BasketContext';
import Icon from '@components/_ui/Icon';
import toTitleCase from '../../../utils/toTitleCase';


const PergolaSummary = () => {
    const { basket } = useBasketContext();

    return basket.module && (
        <div className="cell cluster--tight">
            <div className="configurator__overview">
                <div className="configurator__summary">
                    <span className="text-label">Summary</span>
                    <p className="text-metadata">{basket.module.description} in {toTitleCase(basket.woodType)}, with {toTitleCase(basket.fixingType)} Fixing</p>
                </div>
                <div className="configurator__subtotal">
                    <span className="text-label">Subtotal</span>
                    <p className="text-price">£{basket.calculatedPrice || 'N/A'}</p>
                </div>
                <div className="configurator__submit">
                    <button type="submit" className="button button--fullwidth button__justify">
                        <span>Proceed to checkout</span>
                        <Icon id="arrow-right" className="button__icon" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PergolaSummary;
