import React from 'react';
import WoodTypeRadioInput from '@components/forms/WoodTypeRadioInput';
import { useBasketContext } from '@data/context/BasketContext';

const WoodTypeSelector = () => {
    const { basket, setWoodType } = useBasketContext();

    const changeWoodType = (e) => {
        const { value } = e.target;
        setWoodType(value);
    };


    return (
        <div className="flow--small">
            <span className="text-label display--block">Select wood type</span>
            <fieldset className="form-section__fields cluster--tight">
                <div className="form-row">
                    <WoodTypeRadioInput
                        id="wood-type--softwood"
                        name="wood-type"
                        label="24hr High Pressure Treated timber - planed & chamfered"
                        iconId="smooth-wood"
                        value="softwood"
                        priceDiff={basket.module ? basket.module.prices.softwood : undefined}
                        checked={basket.woodType === 'softwood'}
                        onChange={changeWoodType}
                    />
                    <WoodTypeRadioInput
                        id="wood-type--hardwood"
                        name="wood-type"
                        label="Hardwood - Oak"
                        iconId="wood"
                        value="hardwood"
                        priceDiff={basket.module ? basket.module.prices.hardwood : undefined}
                        checked={basket.woodType === 'hardwood'}
                        onChange={changeWoodType}
                    />
                </div>
            </fieldset>
        </div>
    );
};

export default WoodTypeSelector;
