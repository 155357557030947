
import React from 'react';
import { Link } from 'gatsby';
import { useBasketContext } from '@data/context/BasketContext';

const PergolaPreview = () => {
    const { basket } = useBasketContext();

    return basket.module && (
        <div className="cell medium-6 large-4">
            <div className="configurator__preview card flow">
                <Link to="/shop/" className="text-link" title="Choose a different pergola module">Change</Link>
                <img src={basket.module.imageUrl} alt={basket.module.title} className="module-card__image" />
                <div className="flow--small">
                    <div className="flex--justify">
                        <h4 className="text-subheading">{basket.module.title}</h4>
                        <span className="text-price">£{basket.calculatedPrice}</span>
                    </div>
                    <p className="text-metadata">{basket.module.description}</p>
                </div>
            </div>
        </div>
    );
};

export default PergolaPreview;
