import React from 'react';
import FixingTypeRadioInput from '@components/forms/FixingTypeRadioInput';
import { useBasketContext } from '@data/context/BasketContext';

const FixingTypeSelector = () => {
    const { basket, setFixingType } = useBasketContext();

    const onFixingChange = (e) => {
        const { value } = e.target;
        setFixingType(value);
    };

    return (
        <div className="cell medium-6 large-8 flow--large">
            <div className="flow--small">
                <span className="text-label display--block">Select fixing type</span>
                <fieldset className="form-section__fields cluster--tight">
                    <div className="form-row">
                        <FixingTypeRadioInput
                            id="fixing-type--concrete"
                            name="fixing-type"
                            label="Concrete fixing"
                            iconId="concrete-fixing"
                            value="concrete"
                            checked={basket.fixingType === 'concrete'}
                            onChange={onFixingChange}
                        />
                        <FixingTypeRadioInput
                            id="fixing-type--bolt"
                            name="fixing-type"
                            label="Bolt-down fixing"
                            iconId="bolt-fixing"
                            value="bolt"
                            checked={basket.fixingType === 'bolt'}
                            onChange={onFixingChange}
                        />
                        <FixingTypeRadioInput
                            id="fixing-type--spike"
                            name="fixing-type"
                            label="Spike fixing"
                            iconId="spike-fixing"
                            value="spike"
                            onChange={onFixingChange}
                            checked={basket.fixingType === 'spike'}
                        />
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

export default FixingTypeSelector;
