import React from 'react';


import PergolaPreview from './PergolaPreview';
import WoodTypeSelector from './WoodTypeSelector';
import FixingTypeSelector from './FixingTypeSelector';
import PergolaSummary from './PergolaSummary';

const CustomisePergola = () => (
    <section className="wrapper background-color--chalk flow--medium">
        <div className="container">
            <form action="/shop/checkout" className="grid-x grid-margin-x grid-margin-y">
                <PergolaPreview />
                <div className="cell medium-6 large-8 flow--large">
                    <WoodTypeSelector />
                    <FixingTypeSelector />
                </div>
                <hr className="sep sep--light cell" />
                <PergolaSummary />
            </form>
        </div>
    </section>
);

export default CustomisePergola;
